.faq-v2 {
	margin-top: var(--page-margin-top);

	.card {
		background-color: transparent;
		border: 1px solid var(--color-grey-normal);

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 0px 5px;
		}

		h3 {
			color: var(--font-color-main);
		}

		ul {
			list-style: disc;
			padding: 0 0 0 20px;

			li {
				margin: 0 0 10px 0;
				color: #454545;
				font-size: 16px;
			}
		}

		table,
		th,
		td {
			border: solid 1px #000;
			padding: 10px;
		}

	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

}